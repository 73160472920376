import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'
import SEO from '../components/seo'
import BlogPostItem from '../components/blog-post-item'

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
`

const Heading = styled.div`
  background: #fff;

  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

class BlogCategoryPage extends Component {
  render() {
    let { data } = this.props
    let { pageContext } = this.props
    let categoryTitle = pageContext.name
    let blogPostsCount = data.allContentfulBlogPost.edges.length
    return (
      <>
        <Layout pageProps={this.props}>
          <SEO
            title={categoryTitle + ' - Solid Construction & Design Blog '}
            description={this.props.pageContext.description}
            robots={'noindex'}
          />
          <Heading>
            <h1>{categoryTitle} Blog</h1>
            <p>
              {blogPostsCount} post{blogPostsCount > 1 ? 's' : ''}
            </p>
          </Heading>
          <BlogPostsLayoutContainer className="container">
            {data.allContentfulBlogPost.edges.map((item, i) => (
              <BlogPostItem
                key={i}
                title={item.node.title}
                slug={item.node.slug}
                image={item.node.heroImage}
                publishDate={item.node.publishDate}
                categorySlug={item.node.category.slug}
                category={item.node.category.name}
                author={item.node.author.name}
                excerpt={item.node.metaDescription}
              />
            ))}
          </BlogPostsLayoutContainer>
        </Layout>
      </>
    )
  }
}

export default BlogCategoryPage

export const pageQuery = graphql`
  query GetBlogPostsByCategory($slug: String!) {
    allContentfulBlogPost(filter: { category: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            gatsbyImageData(width: 400)
          }
          metaDescription
          author {
            name
          }
          category {
            slug
            name
            description
          }
        }
      }
    }
  }
`
